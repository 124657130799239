/* Add this CSS to your stylesheet */

/* Overlay Styles */
.animation-overlay {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other elements */
  backdrop-filter: blur(2px); /* Optional: Adds a blur effect */
}

/* Spinner Styles */
.spinner {
  width: 60px;
  height: 60px;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
