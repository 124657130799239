/* Loader.css */

/* Background styling */
.loader-background {
  background: #1e293b; /* Tailwind's gray-800 */
  background: radial-gradient(circle at center, #0f172a, #1e293b 70%);
  overflow: hidden;
}

/* Muted Loader animation */
.muted-loader {
  width: 80px;
  height: 80px;
  border: 8px solid transparent;
  border-top: 8px solid #2563eb;   /* Tailwind's blue-600 */
  border-right: 8px solid #059669; /* Tailwind's emerald-600 */
  border-bottom: 8px solid #475569;/* Tailwind's blue-gray-600 */
  border-left: 8px solid #10b981;  /* Tailwind's green-500 */
  border-radius: 50%;
  animation: spin 2s linear infinite;
  box-shadow:
    0 0 10px #2563eb,
    0 0 10px #059669,
    0 0 10px #475569,
    0 0 10px #10b981;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Muted text effect */
.muted-text {
  color: #2563eb; /* Tailwind's blue-600 */
  text-shadow:
    0 0 2px #2563eb,
    0 0 4px #2563eb,
    0 0 6px #2563eb;
  font-size: 1.5rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% {
    text-shadow:
      0 0 2px #2563eb,
      0 0 4px #2563eb,
      0 0 6px #2563eb;
  }
  50% {
    text-shadow:
      0 0 4px #2563eb,
      0 0 6px #2563eb,
      0 0 8px #2563eb;
  }
}
