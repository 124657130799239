.spinner-container {
  padding: 20px;
  color: white;
}

.progress-bar {
  width: 100%;
  background-color: #333;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar-fill {
  height: 20px;
  background-color: #4caf50;
  width: 0;
}

.status {
  margin-bottom: 20px;
}

.video-container {
  margin-top: 20px;
}

.download-button {
  margin-top: 10px;
}
