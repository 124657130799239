.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height */
  width: 100vw; /* Full width */
  background-color: #111827; /* Background color */
}

.spinner {
  color: #f5f5f5; /* Spinner color */
  font-size: 3rem; /* Adjust size as needed */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-toast-container {
  width: auto;
  min-width: 0;
}

.custom-toast {
  background-color: #0f172a !important;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out !important;
}

.custom-toast-body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto !important;
  padding: 0 !important;
}

/* Add this to hide the default toastify icon */
.custom-toast .Toastify__toast-icon {
  display: none !important;
}

